<template>
  <v-container>
    <PageTitle text="Services Offered"/>
    <PageText :text="p1"/>
    <v-row
      class="fill-height"
      align="center"
      justify="center"
    >
      <template v-for="(item, i) in items">
        <v-col
          :key="i"
          cols="12"
          md="4"
        >
          <v-hover v-slot="{ hover }">
            <v-card
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
              @click="$router.push({path: item.to})"
            >
              <v-card-title class="blue darken-4 text-h6 white--text">
                <v-row
                  class="fill-height flex-column"
                  justify="space-between"
                >
                  <p class="mt-4 subheading text-left">
                    {{ item.title }}
                  </p>

                  <div>
                    <p
                    class="ma-0 text-body-1 font-weight-bold font-italic text-left">
                      {{ item.text }}
                    </p>
                  </div>
                </v-row>
              </v-card-title>
              <v-img
                :src="item.img"
                height="225px"
              >
              </v-img>
            </v-card>
          </v-hover>
        </v-col>
      </template>
    </v-row>
</v-container>
</template>

<script>
import PageText from '@/components/PageText.vue';
import PageTitle from '@/components/PageTitle.vue';

const publicPath = process.env.BASE_URL;

export default {
  components: { PageText, PageTitle },
  data: () => ({
    p1: `
      I offer individual therapy for adults. I customize interventions to your unique needs and
      goals while offering a non-judgemental presence. Below is a list of interventions offered in
      my practice.
    `,
    items: [
      {
        title: 'IFS',
        text: 'Internal Family Systems',
        img: `${publicPath}img/nest_doll_ifs.jpg`,
        to: '/ifs',
      },
      {
        title: 'EMDR',
        text: 'Eye Movement Desensitization Reprocessing',
        img: `${publicPath}img/eye_brown_emdr.jpg`,
        to: '/emdr',
      },
      {
        title: 'BSP',
        text: 'Brainspotting',
        img: `${publicPath}img/galaxy_brain.jpeg`,
        to: '/bsp',
      },
      {
        title: 'CBT',
        text: 'Cognitive Behavioral Therapy',
        img: `${publicPath}img/services.jpeg`,
        to: '/cbt',
      },
      {
        title: 'DBT',
        text: 'Dialectical Behavioral Therapy',
        img: `${publicPath}img/dbt_rock_jenga.jpg`,
        to: '/dbt',
      },
      {
        title: 'Clinical Supervision',
        text: 'Individual and Small Group',
        img: `${publicPath}img/cbt.jpeg`,
        to: '/supervision',
      },
    ],
    transparent: 'rgba(255, 255, 255, 0)',
  }),
};
</script>

<style scoped>
.v-card {
  transition: opacity .4s ease-in-out;
}

.v-card-title {
  background-color: grey;
}

.v-card:not(.on-hover) {
  opacity: 0.9;
 }

</style>
